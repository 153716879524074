import React from 'react';
import styles from './App.module.scss'

function DSGVOText() {

    return (
        <>
            <div className=" m-auto mt-4 d-flex justify-content-center fs-6">
                <div className={`${styles.customContainer} d-flex flex-column`}>
                   <h2>Datenschutzerklärung für Pixeltracking in E-Mails</h2>
                   <p> Wir nehmen den Schutz Ihrer Daten sehr ernst und möchten Sie über die Verwendung von
                    Pixeltracking in unseren E-Mails informieren.</p>

                    <h4>Was ist Pixeltracking?</h4>
                    <p >Pixeltracking ist eine Methode der Webanalyse, bei der ein unsichtbares Pixel in einer E-Mail
                    platziert wird. Sobald Sie die E-Mail öffnen und das Pixel lädt, wird ein Signal an den Server
                    gesendet und Informationen über die E-Mail-Öffnung werden gesammelt. Diese Informationen können
                    beispielsweise die IP-Adresse des Empfängers, den verwendeten E-Mail-Client und den Zeitpunkt
                        der Öffnung umfassen.</p>

                    <h4>Warum verwenden wir Pixeltracking?</h4>
                    <p> Wir verwenden Pixeltracking, um das Nutzerverhalten in Bezug auf unsere E-Mails zu analysieren.
                    Beispielsweise können wir durch Pixeltracking erkennen, welche E-Mails geöffnet werden oder
                    welche Links angeklickt werden. Diese Informationen helfen uns, unsere Prozesse optimal
                        auszurichten und besser auf die Bedürfnisse unserer Kunden einzugehen.</p>

                    <h4>Welche Daten werden erfasst?</h4>
                    <p> Bei der Verwendung von Pixeltracking werden Daten wie Zeitpunkt der Öffnung und Klicken von
                    Links erfasst. Darüber hinaus erhalten wir von unserem Dienstleister einen Status über den
                        Zugang, bzw. wann die Mail erstmalig geöffnet wurde.</p>

                    <h4>Wie lange werden die Daten gespeichert?</h4>
                    <p> Die Daten, die bei der Verwendung von Pixeltracking in E-Mails erfasst werden, werden für einen
                    begrenzten Zeitraum gespeichert. Die genaue Speicherdauer hängt von der Art der Daten und dem
                        Zweck der Verarbeitung ab. Nach Ablauf der Speicherdauer werden die Daten gelöscht.</p>

                    <h4>Wer hat Zugriff auf die Daten?</h4>
                    <p> Die bei der Verwendung von Pixeltracking in E-Mails erfassten Daten werden von uns selbst und
                    unseren Dienstleistern verarbeitet. Wir stellen sicher, dass unsere Dienstleister Ihre Daten nur
                    im Rahmen unserer Aufträge und gemäß unseren Anweisungen verarbeiten. Eine Weitergabe Ihrer
                    Daten an Dritte erfolgt nur, wenn wir gesetzlich dazu verpflichtet sind oder Ihre Einwilligung
                        vorliegt.</p>

                    <h4>Wie können Sie uns kontaktieren?</h4>
                    <p> Wenn Sie Fragen zum Datenschutz oder zur Verwendung von Pixeltracking in unseren E-Mails haben,
                        können Sie uns jederzeit per E-Mail oder Telefon kontaktieren.</p>
                    <span><strong>Kontaktinformationen:</strong></span>
                    <span>UNIVERSUM Inkasso GmbH</span>
                    <span>Hanauer Landstr. 164</span>
                    <span>60314 Frankfurt</span>
                    <span>E-Mail: datenschutz@universum-group.de</span>
                   <text>Diese Datenschutzerklärung gilt ab dem 01.03.2023.</text>
                </div>
            </div>
        </>

    )
}

export default DSGVOText;
